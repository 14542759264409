<template>
    <div>
        <p v-if="hintText">{{ hintText }}</p>
        <div class="cost-estimate__checkboxes">
            <v-checkbox :label="option.option.en" v-for="(option, index) in localOptions" :key="index"
                        v-model="option.checked"
                        @change="toggleTextFields(option)"></v-checkbox>
        </div>
        <div class="cost-estimate__input">
            <div class="d-flex" v-for="(item, index) in selectedValues" :key="index">
                <v-text-field
                    filled
                    :label="$t('fields.cost_estimate.insert_value',{item: item.option.en})"
                    :placeholder="$t('fields.cost_estimate.insert_value',{item: item.option.en})"
                    @blur="updateAnswer($event, item)"
                    v-model="item.value"/>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'CostEstimate',
    props: {
        options: {
            type: Array,
            default: () => {
                return [];
            }
        },
        hintText: {
            default: null
        },
        questionId: {}
    },
    data() {
        return {
            localOptions: [],
            selectedValues: [],
            modifiedValues: []
        };
    },
    computed: {
        ...mapState('surveys', ['currentSurveyAnswers'])
    },
    methods: {
        toggleTextFields(option) {
            if (option.checked) {
                option.checked = true;
                option.value = '';
                this.selectedValues.push(option);
            } else {
                option.checked = false;
                this.selectedValues = this.selectedValues.filter(function(item) {
                    return item !== option;
                });
            }
        },
        updateAnswer(event, option) {
            const currentItem = this.modifiedValues.find((item) => {
                return item[option.id];
            });

            if (currentItem) {
                currentItem[option.id] = parseInt(event.target.value);
            } else {
                this.modifiedValues.push({
                    [option.id]: parseInt(event.target.value)
                });
            }

            this.$emit('updateAnswer', this.modifiedValues);
        },
        checkForAnswer() {
            if (this.currentSurveyAnswers) {
                this.currentSurveyAnswers.forEach((answer) => {
                    if (answer[this.questionId]) {
                        this.options.forEach((option) => {
                            const currentAnswers = answer[this.questionId][0];
                            Object.keys(currentAnswers).forEach((item) => {
                                if (option.id === item) {
                                    option.checked = true;
                                    option.value = currentAnswers[item];
                                    this.selectedValues.push(option);
                                    this.modifiedValues.push({
                                        [this.questionId]: currentAnswers[item]
                                    });
                                }
                            });
                        });
                    }
                });
            }
        }
    },
    created() {
        this.localOptions = [...this.options];
        this.modifiedValues = [];
        this.localOptions.forEach((option) => {
            option.checked = false;
            option.value = '';
        });
        this.checkForAnswer();
    }
};
</script>
